.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 3rem; */
}

.dashboardContainer {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 10%;
}

.actionContainer {
  display: grid;
  gap: 2.5rem;
  padding: 2rem 10%;
}

.actionContainer button {
  height: clamp(2rem, 7vh, 4rem);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
