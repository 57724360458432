.error {
  --text-input-border-color: var(--error-colour);
}

.container {
  display: flex;
  flex-direction: column;
}

.label {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--highlight-color);
}

.input {
  font: inherit;
  background: transparent;
  vertical-align: top;
  outline: none;
  line-height: 1;
  font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial',
    'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  background-color: transparent;
  letter-spacing: 0;
  box-shadow: none;
  color: #1f1f21;
  color: var(--input-text-color);
  padding: 0;
  margin: 0;
  width: auto;
  font-size: 16px;
  font-size: var(--text-input-font-size);
  height: 31px;
  height: var(--text-input-height);
  font-weight: 400;
  font-weight: var(--font-weight);
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid var(--text-input-border-color);
  border-radius: 0;
  width: 100%;
}

.input:focus {
  border-color: var(--highlight-color);
}
