.msg {
  padding-left: 8%;
  padding-right: 8%;
  position: relative;
  /* margin-bottom: 12px; */
  display: block;
  clear: both;
  overflow: hidden;
  height: 100%;
}

.msg.msg-continuation {
  margin-bottom: 2px;
}

.message-in,
.message-out {
  border-radius: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  position: relative;
  -webkit-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  max-width: 90%;
  /* cursor: text;
    white-space: pre-wrap;
    overflow: hidden; */
}

.message-out {
  background-color: #dcf8c6;
  float: right;
}

.message-in {
  /* background-color: #fff; */
  /* background-color: lightblue; */
  /* background-color: rgba(0, 0, 0, 0.05); */
  background-color: #efefef;
  float: left;
}

.tail.message-out {
  border-top-left-radius: 7.5px;
  border-top-right-radius: 0px;
}

.tail.message-in {
  border-top-left-radius: 0px;
  border-top-right-radius: 7.5px;
}

.message-text {
  font-size: 13.6px;
  line-height: 19px;
  word-wrap: break-word;
  color: #262626;
  cursor: text;
  white-space: pre-wrap;
  overflow: hidden;
  user-select: text;
}

.message-text::after {
  content: ' \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
  display: inline;
}

.bubble {
  padding: 6px 9px 8px 7px;
  min-width: 110px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.msgContinued {
  margin-top: -8px;
}

.message-out.tail.bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-left-color: #dcf8c6;
  border-right: 0;
  border-top: 0;
  margin-top: -10px;
  margin-right: -7px;
}

.message-in.tail.bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  /* border-right-color: lightblue; */
  /* border-right-color: rgba(0, 0, 0, 0.05); */
  border-right-color: #efefef;
  /*#fff;*/
  border-left: 0;
  border-top: 0;
  margin-top: -10px;
  margin-left: -7px;
}

/* .adminMsg.message-in.tail.bubble:after {
    border-right-color: #ffcc00;
} */

.msgTimeStamp {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  white-space: nowrap;
}

.bubble-text-meta {
  position: absolute;
  bottom: 3px;
  right: 7px;
}

.msgFrom {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  cursor: text;
  user-select: text;
}

.deleteButton {
  --button-quiet-color: rgba(0, 0, 0, 0.3);
}

.deleteButton.myBase {
  float: right;
}

.deleteButton:hover {
  --button-quiet-color: var(--highlight-color);
}
