.submitButton:hover {
  --button-background-color: var(--second-highlight-color);
}

.cancelButton {
  --button-background-color: black;
}

.cancelButton:hover {
  --button-background-color: rgba(0, 0, 0, 0.7);
}
