.adminMapContainer {
  height: 100%;
  border-right: 1px solid #3a3a3a;
  position: relative;
}

.locateNotSelected {
  --fab-text-color: #000;
  opacity: 0.3;
}

.locateSelected {
  --fab-text-color: dodgerblue;
}

.fabLocate {
  position: absolute;
  bottom: 50px;
  right: 16px;
  --fab-background-color: #fff;
  border: 0px solid currentColor;
  z-index: 99999;
}
.fabLocate:active {
  background-color: #fff;
}
@media (max-width: 992px) {
  .adminMapContainer {
    height: 50%;
    border-right: none;
    border-bottom: 1px solid #3a3a3a;
  }
  .adminContainer.mapShow {
    height: 50%;
    overflow: scroll;
  }
}
