:root {
  --highlight-color: #ef6c00;
  --second-highlight-color: #ff9100;
  --error-colour: red;
  --material-text-input-active-color: var(--highlight-color);
  --material-button-background-color: var(--highlight-color);
  --fab-background-color: var(--highlight-color);
  --fab-active-background-color: var(--second-highlight-color);
  --progress-bar-color: var(--highlight-color);
  --progress-bar-secondary-color: var(--second-highlight-color);
  --tabbar-active-color: var(--second-highlight-color);
}
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ons-button:hover {
  --highlight-color: var(--second-highlight-color);
}

/* App colours */

/*
Primary Color: Indigo 500: #3F51B5
Lighter Primary Indigo 300: #7986CB
Darker Primary Indigo 800: #283593
Accent Color: Orange A400: #FF9100
Accent lighter colors: Orange 300:#FFB74D
Accent Darker color: Orange 800: #EF6C00
 #FF9800

 https://material.io/color/#!/?view.left:1&view.right:0&primary.color:3f50b5&secondary.color:FF9100
 */

/*Operation Founder CSS*/
.primaryColorButton {
  background-color: #000;
}
.primaryColorButton:active {
  /* background-color:#FF9100; */
  background-color: #000;
}
.secondaryColorButton {
  /* background-color: #FF9100; */
  background-color: #ef6c00;
}

.secondaryColorButton:active {
  /* background-color: #FF9100; */
  background-color: #ef6c00;
}

.primaryColor {
  color: #3f51b5;
}

.secondaryColor {
  color: #ff9100;
}
.secondaryColor:active {
  color: #ef6c00;
}

a,
.leaflet-bar a {
  color: #ff9100;
  text-decoration: none;
}

a:hover,
.leaflet-bar a:hover {
  color: #ef6c00;
  /* font-weight: bold; */
  text-decoration: none;
}

a:visited {
  color: #ff9100;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

/* General OnsenUI CSS */

.text-page {
  position: absolute;
  left: 16px;
  right: 16px;
}

#createEventPage .text-input--material__label {
  top: 0px;
}

.navigation-bar,
.navigation-bar__center,
.navigation-bar__left,
.navigation-bar__right {
  height: 50px;
  line-height: 56px;
}

.navigation-bar__right {
  float: right;
}

.text-input--underbar:disabled {
  border-bottom: none;
}
.navigation-bar--material,
.navigation-bar--material__center,
.navigation-bar--material__right,
.navigation-bar--material__left {
  height: 56px;
  background-color: #000;
  text-transform: capitalize;
}

.navigation-bar + .page__background + .page__content {
  top: 50px;
}
.navigation-bar--material + .page__background + .page__content {
  top: 56px;
}
.toolbar-button,
.back-button,
.cursor {
  cursor: pointer;
}
.toolbar-button,
:checked + .tab-bar__button,
.back-button {
  color: #ff9100;
}

.toolbar-button--material {
  font-size: 18px;
}
input:checked + .switch__toggle {
  -webkit-box-shadow: inset 0 0 0 2px #ff9100;
  box-shadow: inset 0 0 0 2px #ff9100;
  background-color: #ff9100;
}
/* .checkbox__input:checked{
    color: #FF9100;
} */

.checkbox__checkmark:before {
  border: 1px solid #ff9100;
}
.checkbox--material__checkmark:before {
  border: 2px solid #717171;
}

.checkbox__input:checked + .checkbox__checkmark:before {
  background: #ff9100;
  border: 1px solid #ff9100;
}
.checkbox__checkmark:after {
  top: 7.5px;
  left: 5.5px;
}
.checkbox--material__input:checked + .checkbox--material__checkmark:after {
  top: 5px;
  left: 4px;
}

.button,
ons-list-item {
  cursor: pointer;
}

.ons-icon {
  font-size: 22px;
}

.list__item__left:empty,
.list__item__center {
  margin: 0px;
  min-height: 52px;
}
.list__item__right {
  margin: 0px;
  min-height: 52px;
}
.page__background,
.list__item,
.list__header,
.list__header--material,
.data-table {
  background-color: rgb(252, 252, 252);
}

/*
.navigation-bar__title:first-letter {
    text-transform: uppercase;
} */

/* material design */

.shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
}

.data-table thead {
  padding-bottom: 3px;
}

.data-table thead .data-table__select {
  margin-top: 0;
}

.data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}

.data-table tbody tr.is-selected {
  background-color: #e0e0e0;
}

@media (hover: hover) {
  .data-table tbody tr:hover {
    background-color: #eeeeee;
  }
}

.data-table td,
.data-table th {
  padding: 0 14px 12px 14px;
  text-align: right;
}

.data-table td:first-of-type,
.data-table th:first-of-type {
  padding-left: 24px;
}

.data-table td:last-of-type,
.data-table th:last-of-type {
  padding-right: 24px;
}

.data-table td {
  position: relative;
  vertical-align: middle;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 12px;
  box-sizing: border-box;
}

.data-table td .data-table__select {
  vertical-align: middle;
}

.data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box;
}

.data-table th.data-table__header--sorted-ascending,
.data-table th.data-table__header--sorted-descending {
  color: rgba(0, 0, 0, 0.87);
}

.data-table th.data-table__header--sorted-ascending:before,
.data-table th.data-table__header--sorted-descending:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  content: '\e5d8';
  margin-right: 5px;
  vertical-align: sub;
}

.data-table th.data-table__header--sorted-ascending:hover,
.data-table th.data-table__header--sorted-descending:hover {
  cursor: pointer;
}

.data-table th.data-table__header--sorted-ascending:hover:before,
.data-table th.data-table__header--sorted-descending:hover:before {
  color: rgba(0, 0, 0, 0.26);
}

.data-table th.data-table__header--sorted-descending:before {
  content: '\e5db';
}

.data-table__select {
  width: 16px;
}

.data-table__cell--non-numeric.data-table__cell--non-numeric {
  text-align: left;
}

/* material design cards */

.material-card {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  box-sizing: border-box;
}
.material-card__media {
  background-color: rgb(255, 64, 129);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.material-card__title {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(0, 0, 0);
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
}

.material-card__title.material-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.material-card__title-text {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: inherit;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  -webkit-transform-origin: 149px 48px;
  transform-origin: 149px 48px;
  margin: 0;
}

.material-card__subtitle-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}

.material-card__supporting-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%;
}

.material-card__supporting-text.material-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.material-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box;
}

.material-card__actions.material-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.material-card--expand {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.material-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

/* Title Bar */

.syncTitle {
  position: relative;
  overflow: hidden;
  height: 100%;
  /* width: 300px; */
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mainTitle {
  /* height: 50%; */
  /* vertical-align: middle; */
  /* position: absolute; */
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* line-height: 50%; */
  margin: 0px;
  padding-top: 8px;
  line-height: 24px;
}

.lastSync {
  /* height: 50%; */
  /* position: absolute; */
  font-size: 12px;
  /* top: 13px; */
  font-weight: normal;
  text-transform: lowercase;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px;
  line-height: 18px;
}

/*signIn page*/
.section {
  padding: 11vh 16px;
}
.sectionGray {
  background-color: #efefef;
}

.sectionBorderTop {
  border-top: 3px solid #ef6c00;
}
.sectionBorderBottom {
  border-bottom: 3px solid #ef6c00;
}

@media (min-width: 768px) {
  .centerContentBoxes {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.highlights .col-sm-4,
.highlights .col-lg-4 {
  padding: 16px;
}

.highlights ons-icon {
  margin: auto;
  width: 100%;
  font-size: 50px;
  text-align: center;
}
.input70perWidth {
  width: 70%;
  max-width: 300px;
}

.maxWidth50per {
  max-width: 50%;
}

.maxWidth {
  max-width: 400px;
}

.logoBackground {
  padding: 0px 16px;
}

.packageOfferings {
  height: 500px;
}
.packageTitle {
  text-align: center;
}
@media (min-width: 768px) {
  #navBar {
    top: 0px;
    position: sticky;
    background-color: #fff;
    z-index: 5;
    vertical-align: middle;
    border-bottom: 1px solid #efefef;
  }
}
#navBarOptions div {
  height: 60px;
  /* padding:16px 2px; */
  color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
  font-size: 14px;
  line-height: 60px;
}
#navBarOptions div:hover {
  border-bottom: #ff9100 2px solid;
  color: #ff9100;
}
#navBarOptions div:active {
  border-bottom: #ff9100 2px solid;
  color: #ff9100;
  background-color: rgba(255, 145, 0, 0.3);
}
.navActive {
  border-bottom: rgba(255, 145, 0, 0.5) 2px solid;
}

#navBar img {
  height: 40px;
  /* margin-top:-4px; */
}

@media (max-width: 768px) {
  .xsPadding16 {
    padding: 0px 16px;
  }
  .xsHide {
    display: none;
  }
}

/* @media screen and (min-width: 650px) {
    .logoBackground {
        width: 100%;
        background-image: url(../img/background.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 32px 0px 32px;
    }
    .signInFormDiv {
        min-width: 300px;
        width: 40%;
        max-width: 400px;
        float: right;
        background-color: #fff;
        padding: 0px 0px 16px 16px;
    }
    .asUsedByLogo {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: 0px auto 0px 16px;
    }
} */

/*sign Up page*/

.text-input--underbar:invalid {
  border-bottom: 0px solid #ff0000;
}

/*LoginPage*/

.fullWidthInput {
  width: 100%;
}

.pageDiv {
  /*
    position: absolute;

    left: 16px;
    right: 16px;
    z-index: 2;
*/
  padding: 0px 16px;
  /*background: #ccc;*/
}
#signInLogo {
  width: 100%;
  max-height: 34vh;
}

.loginLogo {
  max-height: 25vh;
  width: auto;
  height: auto;
  max-width: 100%;
  /* width: 100%;
    max-width: 800px; */
  display: block;
  margin: auto;
}

#baseSelectDialog {
  padding: 16px;
}

/* #baseCode {
    padding:4px;
    border-bottom:1px solid #efefef;
} */
#evtVersion {
  position: absolute;
  font-size: 12px;
  right: 16px;
  top: 0px;
}

/*Cards*/

.card {
  /* display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; 
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    /* min-height: 200px; */
  overflow: hidden;
  /* width: 330px; */
  z-index: 1;
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cardMediaDiv {
  width: 100%;
}

.cardMedia {
  width: 100%;
  max-height: 20vh;
  margin: auto;
  /* max-width: 150px; */
  text-align: center;
  display: block;
}

.cardTRButton {
  position: absolute;
  right: 16px;
  top: 16px;
}

.cardIconButton {
  width: 36px;
  height: 32px;
  border-radius: 50%;
  font-size: 24px;
  /* height: 32px; */
  margin-left: 0;
  margin-right: 0;
  /* min-width: 32px; */
  padding: 0;
  overflow: hidden;
  color: #000;
}

.evtInstructionsShow {
  position: absolute;
  right: 16px;
}

#createNewEvent {
  color: rgba(0, 0, 0, 0.54);
}

#createNewEvent i {
  width: 100%;
  font-size: 5rem;
  text-align: center;
}

#createNewEvent .title {
  font-size: 2rem;
  text-align: center;
  margin: 16px auto;
}

/* .cardIconButton .ripple {
    width: 36px;
    height: 36px;
} */

/* eventSelectionPage.html */

#createNewEvent {
  padding: 16px 0px;
}

.eventTitle {
  font-weight: bold;
  margin-bottom: 8px;
  padding: 8px;
  /* background: #7986CB; */
  /* background: #EF6C00; */
  /* background: #283593; */
  /* background: #3F51B5 !important; */
  background-color: #2c2c2c;
  color: #fff;
}

#eventSelectionPage .page__content {
  background-color: #ededed !important;
}

/*flex*/

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flexRow {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flexColumn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flexWrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flexAlignFlexStart {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexAlignCenter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flexSpaceBetween {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flexJustifyCenter {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*create Event page*/

.eventItemTitle {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  text-align: left;
}

.eventItemCaption {
  -ms-flex-item-align: end;
  align-self: flex-end;
  text-align: left;
  /*    flex:0, auto;*/
}

.eventItemSwitch {
  -webkit-box-flex: 1;
  -ms-flex: 1, 1, auto;
  flex: 1 1 auto;
  text-align: right;
}

.eventItemSpacer {
  width: 90px;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 100%;
}

.bigEventSetUpButtons .ripple {
  z-index: -10;
}

/* .maxScore {
    flex: auto;
}

.maxScoreInput {
    flex: auto;
    text-align: right;
} */

#createEventPage .baseSetUp .caption {
  text-align: left;
}

#eventBannerInputLabel {
  width: 100%;
  max-height: 300px;
}

.baseMaxScore,
.basePassword {
  max-width: 40%;
}

/*Page 1*/

#p1TopHalf {
  /*height: 50%;*/
  width: 100%;
}

#p1BottomHalf {
  width: 100%;
}

.quickAddInput {
  width: 200px;
  max-width: 100%;
}

.rotate270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.chevron {
  font-size: 26px;
}

.baseInstructions {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 16px 8px 24px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  background-color: rgba(255, 183, 77, 0.15);
}

.logOffRouteFalse {
  display: none;
}

@media screen and (min-width: 768px) {
  .quickAddInput {
    width: 400px;
  }
}

@media screen and (min-width: 992px) {
  .quickAddInput {
    width: 600px;
  }
}

@media screen and (min-width: 1200px) {
  .quickAddInput {
    width: 850px;
  }
}

#fullEditFab {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

#submitQuick {
  margin-right: 16px;
}

#cancelSubmitQuick {
  background-color: #fff;
  color: #000;
  /*    opacity: 0.7;*/
  /*    border: solid 1px;*/
  margin-right: 16px;
  border: 1px solid #ccc;
}
#cancelSubmitQuick.button--material {
  border: 0px;
}

.logTable {
  width: 100%;
}

.data-table {
  border: none;
}

.offlineMessage,
.warning {
  color: #a30d0d;
  text-align: center;
  font-size: 16px;
}

.offlineMessageMap {
  color: #a30d0d;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999999;
}

.lastSync .offlineMessage {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize !important;
}

.participantRef {
  text-transform: capitalize;
}

/* -- Admin Page -- */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tab-bar--material {
  background-color: #000;
}
.padlockLocked {
  width: 16px;
  /* background-image: url(../img/hq-lock.png); */
  background-repeat: no-repeat;
  background-position: left;
}

.tableSelected {
  /* background-color: #3F51B5; */
  color: #fff;
  background-color: #ffb74d;
}

.logTable thead th,
#logsTable td,
#adminLogsTable td,
#lastSeenTable td,
#leaderboardTable td {
  text-align: center;
}

@media (hover: hover) {
  .data-table tbody tr:hover {
    /* background-color: #7986CB; */
    color: #fff;
    background-color: #aaa;
  }
}

#patrolSearchInput {
  width: 100%;
  max-width: 30vw;
  margin-left: 16px;
  /* margin-right: 16px; */
}

#patrolSearchInput .text-input--material {
  color: #fff;
  background-image: linear-gradient(
    to top,
    transparent 1px,
    rgba(255, 255, 255, 0.5) 1px
  );
}

#patrolSearchInput .text-input--material__label {
  color: rgb(255, 255, 255);
}

#adminPage .right {
  max-width: 100%;
  min-width: 72px;
  width: auto;
}

#adminPage .center {
  width: auto;
}

@media (max-width: 410px) {
  .adminHide {
    display: none;
  }
}

.adminSummaryIcons ons-icon:first-child {
  padding-right: 4px;
}

.adminContainer.mapShow {
  height: 100%;
  overflow: auto;
}

/* .adminFlexContainer{
    display:flex;
    flex-direction: column;

}

@media(min-width:992px){
    .adminFlexContainer{
        flex-direction: row;
    }
    .adminContainer{
        order:1;
    }
} */

/*.data-table th:first-of-type,
.data-table td:first-of-type {
        padding-left: px;
}*/

.data-table td,
.data-table th {
  padding-left: 16px;
  padding-right: 16px;
  text-align: right;
}

.data-table th:last-of-type,
.data-table td:last-of-type,
.data-table th:first-of-type,
.data-table td:first-of-type {
  padding-right: 16px;
}

.data-table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 2px rgba(0, 0, 0, 0.14);
}

/* Event Summary */

#eventSummaryPage *:not(input):not(textarea):not(select) {
  user-select: text;
}

#eventSummaryPage .evtSendEmail {
  user-select: none;
}

#evtSummaryEvtUser {
  margin-top: 16px;
  /* border: 1px #000 solid; */
  /* background-color: #7986CB; */
  /* background-color: #3F51B5; */
  /* background-color: #002984; */
  background-color: #ff9100;
  /* background-color: #FFB74D; */
  /* background-color: #EF6C00; */
  color: #fff;
  /* color: #000; */
  border-radius: 3px;
}

#evtSummaryEvtUserContents {
  padding: 8px 16px;
  font-size: 14px;
  /* -webkit-user-select: text;
    /* Chrome all / Safari all */
  /* -moz-user-select: text; */
  /* Firefox all */
  /* -ms-user-select: text; */
  /* IE 10+ */
  /* user-select: text; */
}
#evtSummaryEvtUserContents .caption {
  margin-top: 8px;
}

#evtSummaryBases,
#evtSummaryEvtDetails,
#evtSummaryAdminDetails {
  margin-left: 8px;
}

.evtSummaryBasesTitle {
  margin-bottom: 6px;
  margin-top: 16px;
  font-size: 16px;
}

.baseSummaryInfo {
  border-bottom: solid 1px #e2e2e2;
  position: relative;
}

.baseSummaryIcons {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #ccc;
  font-size: 25px;
  line-height: 25px;
}
.baseSummaryIcons ons-icon {
  padding-left: 4px;
}

.baseSummaryIconActive {
  /* color:#FF9100; */
  /* color:#FFB74D; */
  /* color:#fcc779; */
  color: rgba(255, 183, 77, 0.9);
}
/* --- Message CSS --- */

#scrollToBottomFab {
  bottom: 85px;
  position: absolute;
  top: -66px;
  right: 16px;
}

#scrollToBottomFab ons-icon {
  vertical-align: middle;
}

#messagesPage .page__content {
  border: none;
  bottom: 0px;
}

.newMessages {
  color: #ff9100;
}

#scrollMessageBadge {
  position: absolute;
  top: -20px;
  right: 6px;
  background-color: rgba(255, 145, 0, 0.8);
}

#newMessages {
  height: 44px;
  position: relative;
}

/* #messagesPage .page__background {
    background-color: #EF6C00 !important;
    background-color: red !important;
} */

#messageWindow {
  margin-top: 16px;
  margin-bottom: 15px;
  position: relative;
}

#messageInputToolbar {
  height: 0px;
  overflow: unset;
}

.messageInputArea {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* background-color: rgba(40, 53, 147, 0.5); */
  /* background-color: #3e3e3e; */
  background-color: rgba(188, 188, 188, 0.5);
}
.bottom-bar {
  background-color: #ccc;
}

.messageInputContainer {
  margin: 9px 55px 9px 8px;
  font-size: 15px;
  line-height: 20px;
  /* width: 90vw; */
  border-radius: 25px;
  /* border:1px solid #3e3e3e; */
  background-color: #fff;
  /* border: 1px solid #000; */
  /* box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13); */
  /* box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4); */
  /* box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12); */
}

#messageInput {
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 4px 16px;
  font-weight: normal;
  max-height: 210px;
  max-height: 30vh;
  min-height: 38px;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 2px;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  /* border-left: 0.1px solid transparent; */
  -webkit-user-select: text;
  /* Chrome all / Safari all */
  -moz-user-select: text;
  /* Firefox all */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
}

#sendMessage {
  position: absolute;
  right: 8px;
  top: 9px;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13); */
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14),
    0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
}

#sendMessage i {
  position: absolute;
  right: 7px;
  top: 8px;
}

.placeholder {
  padding-top: 12px;
  margin: 4px 24px;
  font-weight: normal;
  font-size: 15px;
  /* min-height: 30px; */
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  /* position: relative; */
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  border-left: 0.1px solid transparent;
}

.dateBubble {
  /* background-color: #7986CB; */
  background-color: #ffb74d;
  border-radius: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  position: relative;
  /* box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13); */
  max-width: 150px;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 120px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 11.6px;
  line-height: normal;
  word-wrap: break-word;
  /* color: #262626; */
  color: #fff;
  cursor: text;
  white-space: pre-wrap;
  overflow: hidden;
  font-weight: bold;
  padding: 6px;
  vertical-align: middle;
}

.dateMsgDivider {
  position: -webkit-sticky;
  position: sticky;
  top: 6px;
  z-index: 3;
}

#topDateMsgDivider {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/* .adminMsg {
    background-color: #ffcc00;
} */

.color-0 {
  color: #ff0000;
}

.color-1 {
  color: rgb(123, 200, 44) !important;
}

.color-2 {
  color: #6bcbef !important;
}

.color-3 {
  color: #e542a3 !important;
}

.color-4 {
  color: #91ab01 !important;
}

.color-5 {
  color: #ffa97a !important;
}

.color-6 {
  color: #1f7aec !important;
}

.color-7 {
  color: #dfb610 !important;
}

.color-8 {
  color: #029d00 !important;
}

.color-9 {
  color: #8b7add !important;
}

.color-10 {
  color: #fe7c7f !important;
}

.color-11 {
  color: #ba33dc !important;
}

.color-12 {
  color: #59d368 !important;
}

.color-13 {
  color: #b04632 !important;
}

.color-14 {
  color: #fd85d4 !important;
}

.color-15 {
  color: #8393ca !important;
}

.color-16 {
  color: #ff8f2c !important;
}

.color-17 {
  color: #a3e2cb !important;
}

.color-18 {
  color: #b4876e !important;
}

.color-19 {
  color: #c90379 !important;
}

.color-20 {
  color: #ef4b4f !important;
}

/* General functions */

.copyableArea {
  -webkit-user-select: text;
  /* Chrome all / Safari all */
  -moz-user-select: text;
  /* Firefox all */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
@media (min-width: 768px) {
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.08);
  /* background-color:transparent; */
}

/* Text styles */

h1 {
  font-size: 20px;
  font-weight: 700;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 16px;
}

.caption {
  font-size: 12px;
}

.h3NoStyling {
  font-size: 14px;
  font-weight: 400;
}

.txtCenter {
  text-align: center !important;
}

.txtRight {
  text-align: right !important;
}

.txtLeft {
  text-align: left !important;
}
.txtJustify {
  text-align: justify !important;
}

.relative {
  position: relative;
}

.bold {
  font-weight: bold !important;
}

.borderBottom {
  border-bottom: thin solid #ccc;
}

.sentanceCase {
  text-transform: lowercase;
}

.sentanceCase:first-letter {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.keepAsWritten {
  text-transform: none;
}

.passwordFont {
  font-family: 'Consolas', serif !important;
  font-size: medium;
}

hr {
  margin: 5vh 5vw;
}

/*element styles*/

.marginAuto {
  margin: auto;
}

.marginRight {
  margin-right: 16px;
}

.marginLeft {
  margin-left: 16px;
}

.marginTop {
  margin-top: 16px;
}

.marginTop32px {
  margin-top: 32px;
}
.marginTop64px {
  margin-top: 64px;
}

.marginBottom {
  margin-bottom: 16px;
}

.noMarginTop {
  margin-top: 0px;
}

.marginLeftAuto {
  margin-left: auto;
}

.paddingBottom {
  padding-bottom: 16px;
}
.paddingRight {
  padding-right: 16px;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

/* page indicators */

.pageIndicatorHolder {
  /*position: absolute;*/
  width: 100%;
  /*bottom: 16px;*/
  margin: 0 auto 16px;
  -ms-flex-item-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*    align-items:flex-end;*/
}

.pageIndicator {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #000;
}

.pIActive {
  opacity: 0.4;
  margin-left: 1px;
  margin-right: 1px;
}

.pIDeactive {
  opacity: 0.1;
  margin-left: 1px;
  margin-right: 1px;
}

/* alerts */

.alert-dialog-mask--material {
  background-color: rgba(0, 0, 0, 0.6);
}

.alert-dialog {
  max-height: 80vh;
  overflow-y: auto;
  max-width: 90vw;
  overflow-x: auto;
  width: clamp(200px, 100vw, 400px);
}

/* --- MAP AREA --- */

/* The #map and body settings allow the map to not have a scroll bar and remain full screen at all times, the map z-index can be changed but do not change the other settings */

#map {
  height: 100%;
  width: 100%;
  z-index: -1;
}

/* body {
    height: 100vh;
    width: 100vw;
} */

/* --- Menu Styling ---*/

#menu {
  width: 80%;
  max-width: 295px;
}

.menuShadow {
  -webkit-box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.12),
    2px 0px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.12), 2px 0px 2px rgba(0, 0, 0, 0.24);
}

ons-splitter-mask {
  background-color: rgba(0, 0, 0, 0.3);
}

.menuTopBox {
  width: 100%;
  height: 100px;
  background-color: #000;
}

.menuTopBoxContent {
  width: 100%;
  margin: auto;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

#opFounderMenu {
  cursor: pointer;
}
#opFounderMenu > .list-item :hover,
#opFounderMenu > a :hover {
  background-color: #ccc;
}
#opFounderMenu .list-item {
  padding-left: 0;
}
#opFounderMenu .list-item > div {
  padding-left: 14px;
}

/* --- Search Bar ---*/

.topBar {
  position: fixed;
  top: 15px !important;
  width: 90%;
  margin: auto;
  border: 1px;
  border-color: #000;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 1;
  background-size: 100% 0px;
}

.navigation-bar--material__center:last-child {
  margin: 0px;
}

#menuDiv {
  width: auto;
}

.androidMenuIcon {
  color: #000;
  opacity: 0.54;
  margin: 0px !important;
}

.menuButton {
  padding: 0px 16px 0px 16px;
}

.searchBar {
  border: 0px;
  font-size: 1em;
  width: 50px;
}

.clearSearchBar {
  padding: 0px 16px 0px 16px;
}

/* --- FAB BUTTONS --- */

.secondaryFab {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.7);
}

/*The fab settings define the fab button's location */

/*.fab {
    right: 16px;
    top: auto;
    left: auto;
    position: absolute;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fabIcon {
    vertical-align: 0%;
}

#fabLocate {
    bottom: 88px;
    background-color: #fff;
    border: 0px solid currentColor;
}

#fabPark {
    bottom: 16px;
}*/

/* These settings define the fab icon's locations to align them centrally*/

.circle-loader {
  fill: transparent;
  stroke: #ff9100;
  margin: auto;
  padding: 5px;
  stroke-width: 5;
  -webkit-animation: dash 2.2s ease infinite, rotate 2.2s linear infinite;
  animation: dash 2.2s ease infinite, rotate 2.2s linear infinite;
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -20;
  }
  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -20;
  }
  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.circle-loader-container {
  position: absolute;
  width: 100%;
  top: 80px;
  margin: 0 auto;
  -ms-flex-item-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*    z-index: -10;*/
}

#messagesPage .circle-loader-container {
  position: static;
  margin-top: 16px;
  z-index: 99999;
}

.circle-loader-background {
  /*    position: absolute;*/
  /*    left:48%;*/
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #fff;
  /*
    border: 1px;
    border-color: #ccc;
*/
  /*    z-index: 999999;*/
  /*    border-style: solid;*/
  -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16),
    0 1px 4px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16),
    0 1px 4px 1px rgba(0, 0, 0, 0.23);
}

/* Leaderboard */

/* Generic Styles */

.hide {
  display: none;
}

.fixed {
  position: fixed;
}

.roundedCorners {
  border-radius: 2px;
}

.test {
  background-color: red;
  height: auto;
  width: 100%;
  top: 100px;
  position: absolute;
  display: block;
}

/* Bing Logo CSS */

.bingLogo {
  z-index: 9999999;
  padding: 5px;
  min-width: 50px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
.leaflet-container .leaflet-control-attribution {
  background-color: transparent;
}
.leaflet-bottom.leaflet-left {
  max-width: 80%;
}

/* Map pin CSS */

.trackerPin {
  z-index: 3;
  background-color: white;
  text-align: center;
  font-size: 10px;
  width: auto;
  min-width: 50px;
}

/*--Update Page--*/

#updateEventUpdate {
  width: 100vw;
  height: 40vh;
  font-size: 40vh;
  text-align: center;
  vertical-align: middle;
  color: #ffb74d;
}

.updateFiller {
  width: 100vw;
  height: 30vh;
}

/* SVG logo */

#svg {
  stroke: red;
  fill: green;
}

/* Stripe */
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Pricing page */
#pricingPageLogo {
  margin-top: 32px;
}

@media (min-width: 768px) {
  .xsShow {
    display: none;
  }
}

#pricingLongLogoSVG {
  width: 100%;
}

.pricingDivs {
  padding: 16px;

  text-align: justify;
  /* border:1px solid #ededed; */
}
.pricingTitle {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #ff9100;
  border-bottom: 2px solid #ff9100;
  padding: 16px;
  margin-bottom: 16px;
}
.pricingPageTitle {
  text-align: center;
  font-weight: bold;
  font-size: 2.5em;
  /* margin: 16px 0px; */
  /* color:#fff; */
}
.pricingPageTitleBackground {
  background-color: transparent;
  color: #000;
  text-align: center;
}
.prices {
  font-size: 24px;
  font-weight: bold;
}
.section h2 {
  font-size: 1.8em;
}
.section h3 {
  font-size: 1.2em;
}
.jumbotron p {
  font-size: 1.3em;
  margin: 16px;
}

#pricingPage .page__content + {
  top: 0px;
}
.orangeMenuButton {
  color: #ff9100;
  line-height: 50px;
}

@media (max-width: 768px) {
  .jumbotron p {
    font-size: 1em;
  }
  .pricingPageTitle {
    font-size: 1.8em;
  }
  .section h2 {
    font-size: 1.5em;
  }
  #customerDetails {
    border-top: 3px solid #ef6c00;
  }
}

#customerDetails {
  padding: 0px 16px 16px;
}
#eventPaymentSummary {
  padding: 0px 16px 0px;
  /* overflow-y: scroll; */
  font-size: 12px;
}

#eventPaymentSummary ons-icon {
  color: #ff9100;
  font-size: 35px;
}

#eventPaymentSummary .disabled {
  color: #ccc;
}

.infoCircle {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 16px !important;
  padding-right: 8px;
}

#checkoutPage .caption,
#signUpPage .caption {
  color: rgba(0, 0, 0, 0.5);
}

#eventPaymentSummary .bold {
  font-size: 1.2em;
}
@media (min-width: 768px) {
  #eventPaymentSummary {
    height: 100%;
    border-left: 1px solid #efefef;
    min-height: 600px;
  }
  #paymentSummary {
    height: 100%;
    height: fit-content;
  }
}

@media (min-width: 768px) and (min-height: 750px) {
  #payment-form {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

@media (max-height: 650px) {
  #eventPaymentSummary div {
    font-size: 12px;
  }
}

select {
  flex: 1;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color: #313b3f;
  cursor: pointer;
  background: transparent;
  padding: 4px;
  border-bottom: 1px solid #afafaf;
}

select:focus {
  color: #313b3f;
}

.select::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  /* background-image: url('../img/dropdown.svg'); */
  pointer-events: none;
}

select option:checked {
  color: #ef6c00;
  font-weight: bold;
}

#country {
  display: flex;
  align-items: center;
}

#country select {
  margin: 0 0 0 -30px;
  padding: 0 15px 0 30px;
}

#country::before {
  display: inline-flex;
  content: '';
  width: 21px;
  height: 15px;
  /* background: url('../img/flags.svg'); */
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

#country.at::before {
  background-position: -165px -10px;
}
#country.au::before {
  background-position: -196px -10px;
}
#country.be::before {
  background-position: -227px -10px;
}
#country.br::before {
  background-position: -351px -10px;
}
#country.ca::before {
  background-position: -382px -10px;
}
#country.ch::before {
  background-position: -475px -10px;
}
#country.cn::before {
  background-position: -41px -35px;
}
#country.de::before {
  background-position: -134px -35px;
}
#country.dk::before {
  background-position: -196px -35px;
}
#country.es::before {
  background-position: -320px -35px;
}
#country.eu::before {
  background-position: -351px -35px;
}
#country.fi::before {
  background-position: -382px -35px;
}
#country.fr::before {
  background-position: -413px -35px;
}
#country.gb::before {
  background-position: -475px -35px;
}
#country.hk::before {
  background-position: -41px -60px;
}
#country.ie::before {
  background-position: -196px -60px;
}
#country.it::before {
  background-position: -351px -60px;
}
#country.jp::before {
  background-position: -444px -60px;
}
#country.lu::before {
  background-position: -258px -85px;
}
#country.mx::before {
  background-position: -475px -85px;
}
#country.nl::before {
  background-position: -103px -110px;
}
#country.no::before {
  background-position: -134px -110px;
}
#country.nz::before {
  background-position: -165px -110px;
}
#country.pt::before {
  background-position: -413px -110px;
}
#country.se::before {
  background-position: -103px -135px;
}
#country.sg::before {
  background-position: -134px -135px;
}
#country.us::before {
  background-position: -475px -135px;
}

/* What is checkpoint live diagram animation */

@-webkit-keyframes chkpt {
  10% {
    fill: #7bc82c;
  }
  20% {
    fill: #000;
  }
}

@keyframes chkpt {
  10% {
    fill: #7bc82c;
  }
  20% {
    fill: #000;
  }
}

@-webkit-keyframes admin {
  12%,
  42% {
    fill: #7bc82c;
  }
  32%,
  62% {
    fill: #000;
  }
}

@keyframes admin {
  10%,
  25%,
  40% {
    fill: #7bc82c;
  }
  15%,
  30%,
  50% {
    fill: #000;
  }
}

#whatIsCheckpointLive .chkptAnimation {
  animation-name: chkpt;
  animation-duration: 10s;
  animation-delay: 8s;
  animation-iteration-count: infinite;
}

#whatIsCheckpointLive {
  order: -1;
}

#whatIsCheckpointLive #chkpt1-in,
#whatIsCheckpointLive #chkpt1-device {
  animation-delay: 2s;
}
#whatIsCheckpointLive #chkpt2-in,
#whatIsCheckpointLive #chkpt2-device {
  animation-delay: 3.5s;
}
#whatIsCheckpointLive #chkpt3-in,
#whatIsCheckpointLive #chkpt3-device {
  animation-delay: 5s;
}
#whatIsCheckpointLive #admin-out {
  animation-name: admin;
  animation-delay: 2.1s;
}

.largeFont {
  font-size: 22px;
  font-weight: bold;
}

#signInPage h2 {
  color: #ef6c00;
}

/* Leaflet cluster markers */
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: 700;
  text-align: center;
  border-radius: 15px;
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 16px;
  color: #000;
  /* font: 16px "Helvetica Neue", Arial, Helvetica, sans-serif; */
}
.marker-cluster span {
  line-height: 30px;
}
/* leaflet layer control */
.leaflet-iconLayers {
  pointer-events: none;
}

.leaflet-iconLayers-layersRow {
  display: table;
  pointer-events: auto;
}
.leaflet-iconLayers-layerCell {
  display: table-cell;
}

.leaflet-iconLayers_topleft .leaflet-iconLayers-layerCell,
.leaflet-iconLayers_bottomleft .leaflet-iconLayers-layerCell {
  padding-right: 5px;
}
.leaflet-iconLayers_topright .leaflet-iconLayers-layerCell,
.leaflet-iconLayers_bottomright .leaflet-iconLayers-layerCell {
  padding-left: 5px;
}

.leaflet-iconLayers_topleft .leaflet-iconLayers-layerCell,
.leaflet-iconLayers_topright .leaflet-iconLayers-layerCell {
  padding-bottom: 5px;
}
.leaflet-iconLayers_bottomleft .leaflet-iconLayers-layerCell,
.leaflet-iconLayers_bottomright .leaflet-iconLayers-layerCell {
  padding-top: 5px;
}
.leaflet-iconLayers-layerCell_active .leaflet-iconLayers-layer {
  border: 3px solid rgba(255, 145, 0, 0.5);
}
.leaflet-iconLayers-layer {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 0 5px #000;
  border-radius: 3px;
}

.leaflet-iconLayers-layer:hover {
  border: 3px solid #ff9100;
}

.leaflet-iconLayers-layerTitleContainer {
  display: table;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  height: 25%;
  padding: 0;
  border: 0;
  position: absolute;
  bottom: 0%;
  transition: bottom 0.35s ease;
}

.leaflet-iconLayers-layerTitle {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}

.leaflet-iconLayers-layerCell_hidden {
  display: none;
}
.leaflet-iconLayers-layerCell_active .leaflet-iconLayers-layer {
  cursor: default;
}
/* .leaflet-iconLayers-layerCell_active .leaflet-iconLayers-layerCheckIcon { display: block; } */
