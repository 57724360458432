.button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, roboto, 'Helvetica Neue', 'Helvetica', 'Arial',
    'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-weight: var(--font-weight);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-decoration: none;
  padding: 4px 10px;
  padding: var(--button-padding);
  font-size: 17px;
  font-size: var(--font-size);
  line-height: 32px;
  line-height: var(--button-line-height);
  letter-spacing: 0;
  color: white;
  color: var(--button-text-color);
  vertical-align: middle;
  background-color: rgb(0, 118, 255);
  background-color: var(--button-background-color);
  border: 0 solid currentColor;
  border-radius: 3px;
  border-radius: var(--button-border-radius);
  transition: none;
}

.outline {
  background-color: transparent;
  border: 1px solid var(--button-background-color);
  color: var(--button-background-color);
}

.large {
  width: 100%;
}
