.messageBadge {
  position: absolute;
  top: 8px;
  height: 22px;
  line-height: 1.5;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 2px 6px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  position: absolute;
  font-weight: bold;
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 12px;
  z-index: 999999;
}

.messagesIcon[modifier='material'] .messageBadge {
  background-color: rgba(255, 145, 0, 0.85);
}
