.container {
  display: grid;
  gap: 0.5rem;
  justify-items: center;
  text-align: center;
  padding: clamp(0.5rem, 3vw, 1rem);
}

.value {
  font-size: clamp(1.1rem, 3vw, 1.75rem);
  font-weight: 600;
}
