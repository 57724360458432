.atBase {
  background-color: rgba(123, 200, 44, 0.25);
}

.lockedLog {
  color: rgba(0, 0, 0, 0.26);
}

.iconActive {
  /* color:rgba(255, 184, 77, 0.9); */
  color: rgba(123, 200, 44, 0.9);
}

.lockedLog .iconActive {
  color: rgba(123, 200, 44, 0.6);
}

.iconNotActive {
  color: rgba(0, 0, 0, 0.15);
}

.lockedLog .iconNotActive {
  color: rgba(0, 0, 0, 0.075);
}

.offRoute {
  background: rgba(255, 183, 77, 0.25);
}

.iconContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.padlock {
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 28px;
  background-size: contain;
}

.lockedLog .padlock {
  background-image: url(./hq-lock.png);
}

.tableRowSelected {
  /* background-color: #3F51B5; */
  color: #fff;
  background-color: #ffb74d;
}
